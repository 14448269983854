<template>
  <div class="home" :class="'page-' + this.$route.name">

    <div class="page-wrap completed-content">
        <img src="../assets/img/svg/completed.svg" alt="bus">

        <div class="title">Reis on edukalt lõpetatud!</div>

        <button class="back" @click="goHome()">Tagasi reisilehele</button>
    </div>
  </div>
</template>


<script>
export default {
    name: 'Completed',
    created(){
      
        if( this.$store.getters.getTravelById(this.$route.params.id) == null || this.$store.state.loggedinUser == null ){
            this.$router.push('/');
            return;
        }
    },

    mounted(){
        window.scrollTo(0, 0);
    }
}
</script>


<style lang="scss">
@import '../assets/scss/_mixins.scss';

.completed-content{
    padding-top: 50px;

    .title{
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: $primary;
        margin: 29px 0 22px;
    }
}

</style>
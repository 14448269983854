<template>
    <div class="page-wrap">
        <div class="footer">
            <p>Probleemide korral võta ühendust:</p>
            <p class="phone"><a href="tel:+37256624059">+372 566 24 059</a></p>
        </div>
        <div class="network-status" :class="[ !$store.state.network_status ? 'show' : '']">
            <span>Töötan offlainis</span>
        </div>


        <div class="loading-status" :class="[ $store.state.loading ? 'show' : '']">
            <span>{{this.$store.state.loading_msg}}</span>
        </div>


        <div class="version-status" :class="[ $store.state.new_version ? 'show' : '']">
            <span>New version downloaded, refreshing...</span>
        </div>


        <div class="login-status" :class="[ $store.state.loggingin ? 'show' : '']">
            <span>Töötan...</span>
        </div>
    </div>
</template>

<script>
export default {

    methods: {
        
    },

    async mounted() {
        try {
            var self = this;
            window.addEventListener('online',  updateOnlineStatus);
            window.addEventListener('offline', updateOnlineStatus);

            function updateOnlineStatus(event) {
                var condition = navigator.onLine ? true : false;

                // if( condition == false ){
                //     self.$store.commit('newVersionRefresh', true);
                // }

                //console.info('Network status', condition);
                self.$store.commit('updateNetworStatus', condition);
                //self.saveData();
                self.updateData();
            }
        } catch(e) {
            console.error(e)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../assets/scss/_mixins.scss';

.footer{
    margin-top: 68px;
    margin-bottom: 100px;
    text-align: left;

    p{
        color: $primary;
        font-size: 12px;
        line-height: 17px;
        margin: 0 0;

        @include media-breakpoint-up(md) {
            font-size: 14px;
            line-height: 20px;
        }

        &.phone{
            font-weight: bold;
            font-size: 14px;
            line-height: 24px;

            @include media-breakpoint-up(md) {
                font-size: 16px;
                line-height: 22px;
            }

            a{
                color: $primary;
                text-decoration: none;
            }
        }
    }
}

.loading-status,
.network-status,
.version-status,
.login-status{
    position: fixed;
    background: $secondary;
    color: $primary;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    bottom: -55px;
    height: 50px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: bottom 0.2s ease;

    &.show{
        bottom: 0;
    }

    &:before{
        content: '';
        background: url(../assets/img/svg/offline.svg) no-repeat center center;
        width: 15px;
        height: 15px;
        display: inline-block;
        margin-right: 7px;
    }
    
}

.loading-status,
.version-status,
.login-status{
    &:before{
        display: none;
    }
}
.version-status{
    background: $gray-3;
    color: $primary;
    
}
</style>

<template>
  <div class="home" :class="'page-' + this.$route.name">
    <div v-show="this.$store.getters.currentSoidud != null">
        <div class="items-list">
          <ul>
            <SoitItem 
            v-for="(soit, index) in this.$store.getters.currentSoidud" 
            v-bind:soit="soit"
            v-bind:index="index"
            v-bind:key="index"
            />
          </ul>
        </div>
    </div>
  </div>
</template>

<script>
import SoitItem from '@/components/SoitItem.vue'

export default {
    name: 'Home',
    components: {
        SoitItem
    },
    mounted(){
      window.scrollTo(0, 0);
        //this.getTeeleData();

        // set current reis ID to vuex
        this.$store.commit( 'setID', this.$route.params.id );
    }
}
</script>


<style lang="scss">
@import '../assets/scss/_mixins.scss';


</style>
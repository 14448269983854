<template>
  <div class="home" :class="'page-' + this.$route.name">

    <div class="page-wrap details">
     
        <div class="table">
            <div class="trow">
                <div class="tcol tlable">Bussijuhi nimi:</div>
                <div class="tcol">...</div>
            </div>
            <div class="trow">
                <div class="tcol tlable">Reg. nr:</div>
                <div class="tcol">{{getData('regNR')}}</div>
            </div>
            <div class="trow">
                <div class="tcol tlable">Reisi number:</div>
                <div class="tcol">{{getData('reis_id')}}</div>
            </div>
            <div class="trow">
                <div class="tcol tlable">Klient:</div>
                <div class="tcol">{{getData('klient')}}</div>
            </div>
            <div class="trow">
                <div class="tcol tlable">Kontakt:</div>
                <div class="tcol">{{getData('kontakt')}}</div>
            </div>
            <div class="trow">
                <div class="tcol tlable">Silt:</div>
                <div class="tcol">{{getData('silt')}}</div>
            </div>
            <div class="trow">
                <div class="tcol tlable">Reisijate arv:</div>
                <div class="tcol">{{getData('reisijaid')}}</div>
            </div>
            <div class="trow">
                <div class="tcol tlable">Planeeritud kestvus:</div>
                <div class="tcol">{{getData('kestvus')}} tunnid</div>
            </div>
            <div class="trow">
                <div class="tcol tlable">Marsruut:</div>
                <div class="tcol">{{getData('marsruut')}}</div>
            </div>
            <div class="trow">
                <div class="tcol tlable">Lisainfo bussijuhile:</div>
                <div class="tcol">{{getData('lisainfo')}}</div>
            </div>

            <div class="juhi_data" v-show="future_item == true">
                <div class="trow">
                    <div class="tcol tlable">Spidomeetri näit baasis:</div>
                    <div class="tcol">{{getData('spido_A')}} KM</div>
                </div>
                <div class="trow">
                    <div class="tcol tlable">Spidomeetri näit lõpus:</div>
                    <div class="tcol">{{getData('spido_L')}} KM</div>
                </div>
                <div class="trow">
                    <div class="tcol tlable">Tangitud kütus:</div>
                    <div class="tcol">{{getData('tangitud')}} L</div>
                </div>
                <div class="trow">
                    <div class="tcol tlable">Bussijuhi märkused:</div>
                    <div class="tcol">{{getData('marked')}}</div>
                </div>
            </div>
        </div>

    </div>


    <div class="page-wrap update-details" v-show="future_item == false">
        
        <h2>Sisesta reisiandmed</h2>

        <div class="input-row">
            <label>Spidomeetri näit baasis (KM):</label>
            <input type="tel" v-model="spido_A" @change="updateInput('spido_A')" />
        </div>
        
        <div class="input-row">
            <label>Spidomeetri näit lõpus (KM):</label>
            <input type="tel" v-model="spido_L" @change="updateInput('spido_L')" />
        </div>
        
        <div class="input-row">
            <label>Tangitud kütus (L):</label>
            <input type="tel" v-model="tangitud" @change="updateInput('tangitud')" />
        </div>
        
        <div class="input-row">
            <label>Bussijuhi märkused:</label>
            <textarea v-model="marked" @change="updateInput('marked')"></textarea>
        </div>

        <button class="submit green" @click="save()">Salvestan</button>

    </div>
  </div>
</template>


<script>
import moment from 'moment';

export default {
    name: 'ReisDetails',

    data() {
        return {
            future_item: false,
            spido_A: '',
            spido_L: '',
            tangitud: '',
            marked: ''
        }
    },


    methods: {
        updateInput: function (key) {
            this.setData(key, this[key]);
        },  


        getData( key ){
          let travel = this.$store.getters.getTravelById( this.$route.params.id );
          //console.log('getHeading', travel);
          return travel != null && key in travel ? this.utf8Decode(travel[key]) : '';
        },

        setData(key, value){
            let reis_id = this.$route.params.id;
            let travels = this.$store.state.travels;

            if(travels != null){
                travels.forEach(function(reis, index){  
                    if( reis.reis_id == reis_id ){

                        console.log('updateInput', travels[index][key] );
                        if( key in travels[index] ){
                            travels[index][key] = value;
                        }
                    }
                    
                });

                this.$store.commit('storeDataLocally', travels);

                
                var self = this;
                setTimeout(function(){
                    self.saveData();
                }, 100);
            }

        },

        save(){
            var self = this;
            this.saveData();
            setTimeout(function(){
                self.$router.push('/reis/'+self.$route.params.id)
            }, 500);
        }
    },


    mounted(){
        window.scrollTo(0, 0);
        this.spido_A = this.getData( 'spido_A' );
        this.spido_L = this.getData( 'spido_L' );
        this.tangitud = this.getData( 'tangitud' );
        this.marked = this.getData( 'marked' );
    },

    created(){
        if( this.$store.getters.getTravelById(this.$route.params.id) == null || this.$store.state.loggedinUser == null ){
            this.$router.push('/');
            return;
        }
        if( this.$store.getters.getTravelById( this.$route.params.id ).startDate > moment().format('YYYY-MM-DD') ){
            this.future_item = true;
        }
        
    }
}
</script>


<style lang="scss">
@import '../assets/scss/_mixins.scss';

.details{
    background: $gray-2;
    padding: 25px 15px;
}

.table{
    .trow{
        display: flex;
        align-items: center;
        padding: 7px 0;
        align-items: flex-start;

        .tcol{
            width: 55%;
            justify-content: flex-start;
            text-align: left;
            display: flex;
            font-size: 14px;
            line-height: 18px;
            color: $primary;

            @include media-breakpoint-up(md) {
                font-size: 16px;
                line-height: 24px;
            }

            &.tlable{
                width: 45%;
                font-weight: bold;
                padding-right: 5px;
            }
        }
    }

    .juhi_data{
        margin-top: 15px;
        padding-top: 15px;

        border-top: 1px solid $gray;
    }
}

.update-details{
    margin: 18px 0;

    @include media-breakpoint-up(md) {
        margin: 35px 0;
    }
}

h2{
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    margin: 0 0 25px;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 26px;
    }
}

.input-row{
    margin-bottom: 12px;

    label{
        display: block;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: left;

        @include media-breakpoint-up(md) {
            font-size: 16px;
            line-height: 26px;
        }
    }
    input,
    textarea{
        display: block;
        border: 1px solid $gray-4;
        box-sizing: border-box;
        border-radius: 3px;
        height: 53px;
        font-size: 16px;
        color: $primary;
        width: 100%;
        padding: 3px 15px;
    }

    textarea{
        height: 110px;
    }
}

</style>
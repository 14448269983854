<template>
  <div class="home" :class="'page-' + this.$route.name">
    <div class="page-wrap">
      <h1>Minu Reisid</h1>
    </div>

    <transition name="fade-in" enter-active-class="animated">
      <div v-show="this.$store.getters.todayTravels != null">
        <h2 class="list-title">Tänased reisid ({{todayDate()}})</h2>
        <div class="items-list">
            <ul>
              <ReisItem 
                v-for="(reis, index) in this.$store.getters.todayTravels" 
                v-bind:reis="reis"
                v-bind:past="false"
                v-bind:key="index"
                />
            </ul>
        </div>
      </div>
    </transition>
    <div v-show="this.$store.getters.todayTravels == null">
      <h2 class="list-title">Teil pole täna ühtegi reisi ({{todayDate()}})</h2>
    </div>


    <transition name="fade-in" enter-active-class="animated">
      <div v-show="this.$store.getters.futureTravels != null">
        <h2 class="list-title">Tuleviku reisid</h2>
        <div class="items-list">
          <ul>
            <ReisItem 
              v-for="(reis, index) in this.$store.getters.futureTravels" 
              v-bind:reis="reis"
              v-bind:past="true"
              v-bind:key="index"
              />
          </ul>
        </div>
      </div>
    </transition>
    

    <transition name="fade-in" enter-active-class="animated">
      <div v-show="this.$store.getters.pastTravels != null">
        <h2 class="list-title">Möödunud reisid</h2>
        <div class="items-list">
          
          <ul>
            <ReisItem 
              v-for="(reis, index) in this.$store.getters.pastTravels" 
              v-bind:reis="reis"
              v-bind:past="true"
              v-bind:key="index"
              />
          </ul>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
// @ is an alias to /src
import ReisItem from '@/components/ReisItem.vue'


export default {
  name: 'Home',
  components: {
    ReisItem
  },

  methods: {
    
  },

  mounted(){
    window.scrollTo(0, 0);

    this.getTeeleData();

    if( this.$store.state.loggedinUser == null ){
        this.$router.push('/');
    }
    
  }

}
</script>


<style lang="scss">
@import '../assets/scss/_mixins.scss';

.fade-in-enter-to {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.home{
  h1{
    text-transform: uppercase;
  }

  .list-title{
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    margin: 0 0 10px;
    text-align: left;
    padding: 0 15px;
    
    @include media-breakpoint-up(md) {
      font-size: 14px;
      line-height: 26px;
    }
  }

  .items-list{
    margin-bottom: 39px;

    ul{
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
}
</style>
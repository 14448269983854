<template>
    <li class="reis-item" :class="past ? 'past-reis':''" @click="open(reis, index)">
        <div class="marsruut">
            {{utf8Decode(reis.marsruut)}}
        </div>

        <div class="meta">
            <div class="date">
                Algus: {{reisDateTime( reis )}}
            </div>
            <div class="status-wrap" v-html="this.getStatus(reis.soidud)"></div>
        </div>
    </li>
</template>

<script>
import moment from 'moment';

export default {
    name: 'Current Reisid',

    data() {
        return {
            status: 0
        }
    },

    props: ['reis', 'past'],
    
    components: {
        
    },

    methods: {

        open( reis, index ){
            let self = this;
            let reis_id = reis.reis_id;

            setTimeout(function(){
                self.$router.push('/reis/'+reis_id);
            }, 50);
        },
        
        getStatus( soidud ){
            if( soidud ){
                var olek = 0;
                var olekud = [];
                var counts = {};
                var total = soidud.length;

                soidud.forEach(function(item, index){
                    olekud.push( item.olek );
                });

                for (const num of olekud) {
                    counts[num] = counts[num] ? counts[num] + 1 : 1;
                }

                let html = '';

                if( counts[0] == total ){
                    html = '<span class="status alustamata">Alustamata</span>';
                }else if( counts[3] == total ){
                    html = '<span class="status juht-lopetatud">Lõpetatud</span>';
                }else if( counts[2] == total ){
                    html = '<span class="status sustem-lopetatud">Süsteemi poolt lõpetatud</span>';
                }else{
                    html = '<span class="status alustatud">Alustatud</span>';
                }

                return html;
            }
        },

        reisDateTime( reis ){
            return moment(String(reis.algusAEG)).format('HH:mm DD.MM.YYYY');
        }
    
    },

    created(){
        
    }
}
</script>

<style lang="scss">
@import '../assets/scss/_mixins.scss';

.reis-item{
    border-bottom: 1px solid $gray;
    padding: 15px 35px 15px 15px;
    text-align: left;
    position: relative;

    @include media-breakpoint-up(md) {
        padding: 20px 35px 20px 15px;
    }

    &:after{
        content: '';
        background: url(../assets/img/svg/arrow-right.svg) no-repeat center center;
        background-size: 100% auto;
        position: absolute;
        top: 50%;
        width: 9px;
        height: 15px;
        right: 12px;
        margin-top: -6px;

        @include media-breakpoint-up(md) {
            width: 12px;
            height: 20px;
            right: 20px;
            margin-top: -10px;
        }
    }

    &:first-child{
        border-top: 1px solid $gray;
    }

    &.past-reis{
        background: $gray-2;

        .marsruut{
            color: $gray-3;
        }
    }
    &:hover,
    &:active,
    &:focus{
        background: $gray-4;
    }
    .marsruut{
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $primary;

        @include media-breakpoint-up(md) {
            font-size: 18px;
            line-height: 28px;
        }
    }

    .meta{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 4px;

        .date{
            font-size: 14px;
            line-height: 14px;
            color: $gray-3;
            margin-right: 10px;

            @include media-breakpoint-up(md) {
                font-size: 16px;
                line-height: 22px;
            }
        }

        .status-wrap{
            display: flex;
        }
        .status {
            display: inline-block;
            border-radius: 15px;
            background: $gray;
            color: $gray-3;
            font-size: 10px;
            line-height: 12px;  
            padding: 3px 8px;

            @include media-breakpoint-up(md) {
                font-size: 12px;
                line-height: 12px;  
                padding: 4px 10px;
            }

            &.alustatud{
                background: $yellow;
                color: #fff;
            }
            &.sustem-lopetatud{
                background: $red;
                color: #fff;
            }
            &.juht-lopetatud{
                background: $green;
                color: #fff;
            }
        }
    }
}

</style>
<template>
    <li class="soit-item" :class="this.$store.state.openSoit == index && future_item == false ? 'open':''">
        <div class="marsruut">
            <div class="soit-date">{{ toDate(soit.aeg_A) }}</div>
            <div class="start column">
                <div>
                    <div class="time">{{ toTime(soit.aeg_A) }}</div>
                    {{utf8Decode(soit.aadress_A)}}
                </div>
                <button v-show="past_item == false && future_item == false" class="green" :class="soit.olek != 0 ? 'disable':''" @click="start(soit, index)">Alustan</button>
            </div>
            <div class="end column">
                <div>
                    <div class="time">{{ toTime(soit.aeg_L) }}</div>
                    {{utf8Decode(soit.aadress_L)}}
                </div>
                <button v-show="past_item == false && future_item == false" class="alert" :class="soit.olek >= 2 ? 'disable':''" @click="stop(soit, index)">Lõpetan</button>
            </div>

            <span v-show="future_item == false" class="open" @click="openSoit(soit, index)"></span>
        </div>

        <div class="details" v-show="future_item == false">
            <div class="row">
                <div class="column">
                    <label>Tegelik alustamis aeg:</label>
                    <input type="time" v-model="aeg_A"  />
                </div>
                <div class="column">
                    <label>Tegelik lõpetamis aeg:</label>
                    <input type="time" v-model="aeg_L"  />
                </div>
            </div>
            
            <button class="submit green" @click="save(soit, index)">Salvestan</button>
        </div>
    </li>
</template>

<script>
import moment from 'moment';

/*
Olekud:
 	0 	alustamata
	1 	alustatud
	2 	süsteemi poolt lõpetatud
	3 	bussijuhi poolt lõpetatud
	91 	bussijuhi poolt lõpetatud
*/

export default {
    name: 'Current Reisid',
    data() {
        return {
            status: 0, 
            future_item: false,
            past_item: false,
            aeg_A: null,
            aeg_L: null
        }
    },
    props: ['soit', 'index'],

    methods: {

        openSoit( soit, index ){
            if( this.$store.state.openSoit != null && this.$store.state.openSoit == index){
                this.$store.commit('setOpenSoit', null);
            }else{
                this.$store.commit('setOpenSoit', index);
            }
        },  

        save( soit, index ){
            let reis_id = soit.reis_id;
            let soit_id = soit.soit_id;
            this.setData(reis_id, soit_id, 'tegelikTime', null, null);

            //this.saveData();
            this.$store.commit('setOpenSoit', null);
        },  
        
        start( soit, index ){
            let reis_id = soit.reis_id;
            let soit_id = soit.soit_id;
            this.setData(reis_id, soit_id, 'olek', 1, 'aeg_A');
        },

        stop( soit, index ){
            let reis_id = soit.reis_id;
            let soit_id = soit.soit_id;
            this.setData(reis_id, soit_id, 'olek', 3, 'aeg_L');

            this.checkIfCompleted();
        },

        checkIfCompleted(){
            let soidud = this.$store.getters.getSoidudByTravelId( this.$route.params.id );
            if( soidud ){
                var olek = 0;
                var olekud = [];
                var counts = {};
                var total = soidud.length;

                soidud.forEach(function(item, index){
                    olekud.push( item.olek );
                });

                for (const num of olekud) {
                    counts[num] = counts[num] ? counts[num] + 1 : 1;
                }

                if( counts[3] == total ){
                    this.$router.push('/reis/completed/'+this.$route.params.id);
                }
            }
        },


        updateInput: function (key) {
            this.setData(key, this[key]);
        },  


        setData(reis_id, soit_id, key, value, aeg){
            var self = this;
            //let soit_id = this.$route.params.id;
            let travels = this.$store.state.travels;

            if(travels != null){
                let now = moment().format('YYYY-MM-DD HH:mm:ss');
                let nowDate = moment().format('YYYY-MM-DD');
                let nowTime = moment().format('HH:mm');
                travels.forEach(function(reis, idx){  
                    if( reis.reis_id == reis_id ){
                        let soidud = reis.soidud;
                        if(soidud != null){
                            let updatePrevious = null
                            soidud.forEach(function(soit, index){  
                                if( soit.soit_id == soit_id ){

                                    switch (key) {
                                        case 'tegelikTime':
                                            if( 'aeg_A' in travels[idx].soidud[index] && 'aeg_L' in travels[idx].soidud[index] ){
                                                let originalDateA = moment(String(travels[idx].soidud[index].aeg_A)).format('YYYY-MM-DD');
                                                let originalDateL = moment(String(travels[idx].soidud[index].aeg_L)).format('YYYY-MM-DD');
                                                // console.log('tegelikTime', self.aeg_A, self.aeg_L);
                                                // console.log('originalDateA', originalDateA);
                                                // console.log('originalDateL', originalDateL);
                                                travels[idx].soidud[index].ajatempel = now;
                                                travels[idx].soidud[index].aeg_A = moment(String(originalDateA +' '+ self.aeg_A)).format('YYYY-MM-DD HH:mm:ss');
                                                travels[idx].soidud[index].aeg_L = moment(String(originalDateL +' '+ self.aeg_L)).format('YYYY-MM-DD HH:mm:ss');
                                                self[aeg] = nowTime;
                                            }
        
                                            break;
                                    
                                        default:
                                            if( key in travels[idx].soidud[index] && (value != '' || value != null) ){
                                                updatePrevious = index;
                                                travels[idx].soidud[index][key] = value;
                                                travels[idx].soidud[index].ajatempel = now;
                                                if( aeg != '' && aeg != null ){
                                                    travels[idx].soidud[index][aeg] = now;
                                                    self[aeg] = nowTime;
                                                }
                                            }
                                            break;
                                    }

                                    
                                }
                            });

                            if( updatePrevious != null ){
                                for (let i = 0; i < updatePrevious; i++) {
                                    console.log('updatePrevious', i);
                                    let olek = travels[idx].soidud[i][key];
                                    if( olek == 0 || olek == 1 ){
                                        travels[idx].soidud[i][key] = 3;
                                        travels[idx].soidud[i].ajatempel = now;
                                    }
                                }
                            }
                        }
                    }
                });   
                this.$store.commit('storeDataLocally', travels);

                var self = this;
                setTimeout(function(){
                    self.saveData();
                }, 100);
            }
        },

    
        getData( key ){
            let travel = this.$store.getters.getSoidudByTravelId( this.$route.params.id );
            //console.log('travel', this.index, travel);
            let value = travel[this.index] != null && key in travel[this.index] ? travel[this.index][key] : '';
            
            switch (key) {
                case 'aeg_A':
                case 'aeg_L':
                        value = moment(String(value)).format('HH:mm');
                        //console.log('value', value);
                    break;
        
            }

            return value;
        },
    },

    mounted(){
        this.aeg_A = this.getData( 'aeg_A' );
        this.aeg_L = this.getData( 'aeg_L' );
        this.$store.commit('setOpenSoit', null);
    },

    created(){
        if( this.$store.getters.getTravelById( this.$route.params.id ).startDate > moment().format('YYYY-MM-DD') ){
            this.future_item = true;
        }
        if( this.$store.getters.getTravelById( this.$route.params.id ).startDate < moment().format('YYYY-MM-DD') ){
            this.past_item = true;
        }
    }
}
</script>

<style lang="scss">
@import '../assets/scss/_mixins.scss';

.soit-item{
    border-bottom: 1px solid $gray;
    padding: 15px;
    text-align: left;

    @include media-breakpoint-up(md) {
        padding: 20px 15px;
    }

    .marsruut{
        display: flex;
        position: relative;
        padding-top: 11px;
        padding-right: 10px;
        z-index: 0;

        @include media-breakpoint-up(md) {
            padding-top: 15px;
        }

        .soit-date{
            position: absolute;
            right: 0;
            top: -5px;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            font-weight: bold;
            color: $primary;

            @include media-breakpoint-up(sm) {
                font-size: 14px;
            }

            @include media-breakpoint-up(md) {
                font-size: 16px;
                line-height: 16px;
                font-weight: bold;
            }
        }

        .open{
            position: absolute;
            width: 40px;
            height: 40px;
            background: none;
            right: -10px;
            top: 50%;
            margin-top: -20px;

            &:after{
                content: '';
                position: absolute;
                background: url(../assets/img/svg/arrow-right.svg) no-repeat center center;
                background-size: auto 15px;
                width: 16px;
                height: 16px;
                right: 13px;
                top: 13px;
                transform: rotate(90deg);
                transition: transform 0.3s ease;

                @include media-breakpoint-up(md) {
                    background-size: auto 20px;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .column{
            padding-right: 30px;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: $gray-3;
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            @include media-breakpoint-up(sm) {
                font-size: 16px;
                line-height: 20px;
            }

            @include media-breakpoint-up(md) {
                font-size: 16px;
                line-height: 22px;
            }

            .time{
                font-weight: bold;
                color: $primary;
                margin-bottom: 3px;
            }

            button{
                margin-top: 10px;
            }
        }
    }

    .details{
        display: none;
        padding: 15px 15px 0;
        margin: 15px -15px 0;
        border-top: 1px solid $gray;
        background: linear-gradient(180deg, #F0F0F0 0%, rgba(248, 248, 248, 0) 10.86%);

        @include media-breakpoint-up(sm) {
            padding: 20px 15px 10px;
        }

        .row{
            display: flex;
            margin: 0 -5px 15px;

            .column{
                width: 50%;
                padding: 0 5px;

                label{
                    display: block;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 21px;
                    text-align: left;

                    @include media-breakpoint-up(sm) {
                        font-size: 14px;
                        line-height: 26px;
                    }
                }
                input{
                    display: block;
                    border: 1px solid $gray-4;
                    box-sizing: border-box;
                    border-radius: 3px;
                    height: 53px;
                    font-size: 16px;
                    line-height: 53px;
                    color: $primary;
                    width: 100%;
                    padding: 3px 15px;
                }

                input[type="time"] {
                    color: $primary;
                    font-size: 16px;
                    width: 130px;
                    padding: 3px 5px;
                }

                input[type=time]::-webkit-datetime-edit-fields-wrapper {
                    display: flex;
                }

                input[type=time]::-webkit-datetime-edit-text {
                    padding: 19px 4px;
                }

                /* Hour */
                input[type=time]::-webkit-datetime-edit-hour-field {
                    background-color: #fff;
                    border-radius: 15%;
                    padding: 19px 5px;
                }

                /* Minute */
                input[type=time]::-webkit-datetime-edit-minute-field {
                    background-color: #fff;
                    border-radius: 15%;
                    padding: 19px 5px;
                }

                /* AM/PM */
                input[type=time]::-webkit-datetime-edit-ampm-field {
                    background-color: #fff;
                    border-radius: 15%;
                    color: $primary;
                    padding: 19px 5px;
                }

            }
        }

        .submit{
            max-width: 180px;
            margin: 0 auto;
        }
    }

    &.open{
        .details{
            display: block;
        }

        .open{
            &:after{
                transform: rotate(-90deg);
            }
        }
    }
}

</style>
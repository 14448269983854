<template>
    <router-link :to="'/reis/details/'+this.$route.params.id" class="travel-details-button">
        Reisi andmed ({{$route.params.id}})
    </router-link>
</template>

<script>

export default {
    name: 'Details Button',
    methods: {
        

    }
}
</script>


<style lang="scss">
@import '../assets/scss/_mixins.scss';

.travel-details-button{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: $gray-3;
    background: $gray-2;
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.25);
    padding: 13px 15px;
    text-transform: uppercase;
    text-decoration: none;

    &:active{
        background: $gray-4;
    }

    &:before{
        content: '';
        background: url(../assets/img/svg/info.svg) no-repeat center center;
        background-size: 16px auto;
        width: 17px;
        height: 17px;
        margin-right: 10px;
        display: inline-block;
    }

    &:after{
        content: '';
        background: url(../assets/img/svg/arrow-right-gray.svg) no-repeat center center;
        background-size: 100% auto;
        position: absolute;
        width: 9px;
        height: 15px;
        right: 15px;
        top: 50%;
        margin-top: -7px;
    }
}
</style>
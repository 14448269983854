<template>
  <div>
    <div class="header">
      <div class="logo-name" @click="goHome()">
        <img src="../assets/img/svg/bus.svg" alt="bus">
        <span class="user-name" v-show="this.$store.state.loggedinUser != null ">{{this.$store.state.loggedinUserName}}</span>
      </div>
      <span class="logout" v-show="this.$store.state.loggedinUser != null && this.$store.state.network_status == true" @click="logoutUser()">
        <img src="../assets/img/svg/logout.svg" alt="bus">
        Logi välja
      </span>
    </div>
    <div class="subheader">
      <navigation v-show="showNavigation()" class="child-view"/>
      <detailsbutton v-show="showDetailsBtn()" class="child-view"/>
    </div>
  </div>
</template>

<script>
import navigation from '@/components/navigation.vue'
import detailsbutton from '@/components/details-button.vue'

export default {
  components: {
    navigation,
    detailsbutton
  },

  methods: {
    logoutUser() {
      this.$store.state.loggedinUser = null;
      this.$store.state.loggediloggedinUserNamenUser = null;
      this.$store.state.travels = null;
      localStorage.removeItem('loggedinUser');
      localStorage.removeItem('loggedinUserName');
      localStorage.removeItem('travelsData');
      localStorage.clear();
      this.$router.push('/');
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../assets/scss/_mixins.scss';
  .header{
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    height: 31px;
    z-index: 999;

    @include media-breakpoint-up(md) {
      height: 50px;
    }

    .logout{
      font-weight: bold;
      font-size: 12px;
      line-height: 24px;
      color: $secondary;
      display: flex;
      align-items: center;

      @include media-breakpoint-up(md) {
        font-size: 14px;
      }
      
      img{
        margin-right: 7px;
      }
    }

    .logo-name{
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 12px;
      line-height: 24px;
      color: $secondary;

      @include media-breakpoint-up(md) {
        font-size: 14px;
      }

      img{
        margin-right: 7px;
      }
    }
  }
</style>

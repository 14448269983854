/**
 * Author:    Gleb Makarov
 * Created:   15.11.2021
 * Contact:   info@blueglass.ee
 * 
 * (c) Copyright by BlueGlass interactive OÜ.
 **/

import { createStore } from 'vuex'
import moment from 'moment';

export default createStore({
  state: {
    apikey: '288XSs8sdf8765a6s57asd56887a6',

    new_version: false,

    loading: false,

    texts: {
      updating: 'Andmete värskendamine...',
      saving: 'Salvestan andmed...',
    },

    loading_msg: 'Andmete värskendamine...',


    network_status: true,

    loggingin: false,
    loggedinUser: null,
    loggedinUserName: '',

    travels: null,

    current_id: null,

    openSoit: null
  },

  getters: {
    todayTravels: state => {
      if( !state.travels || state.travels == null ) return null;

      var date = moment().format('YYYY-MM-DD');

      //console.log('state.travels', state.travels);

      let ordered = state.travels.filter(reis => reis.startDate <= date && reis.endDate >= date ).sort(function(a, b) {
        a = moment(a.algusAEG).format('YYYYMMDDHHmm');
        b = moment(b.algusAEG).format('YYYYMMDDHHmm');
        return a < b ? -1 : 1; // ascending order
      });

      return ordered && ordered.length ? ordered : null;
    },


    pastTravels: state => {
      if( !state.travels || state.travels == null ) return null;

      var date = moment().format('YYYY-MM-DD');
      let ordered = state.travels.sort(function(a, b) {
        a = moment(a.algusAEG).format('YYYYMMDDHHmm');
        b = moment(b.algusAEG).format('YYYYMMDDHHmm');
        return a > b ? -1 : 1; // ascending order
      }).filter(reis => reis.startDate < date && reis.endDate < date );

      return ordered && ordered.length ? ordered : null;
    },


    futureTravels: state => {
      if( !state.travels || state.travels == null ) return null;

      var date = moment().format('YYYY-MM-DD');
      let ordered = state.travels.filter(reis => reis.startDate > date && reis.endDate > date ).sort((a, b) => new Date(b.algusAEG) - new Date(a.algusAEG));

      return ordered && ordered.length ? ordered : null;
    },


    currentTravel: state => {
      if( !state.travels || state.travels == null || state.current_id == null ) return null;
      let travel = state.travels.filter(reis => reis.reis_id == state.current_id );
      if(travel) return travel;
    },

    currentSoidud: state => {
      if( !state.travels || state.travels == null || state.current_id == null ) return null;

      let travel = state.travels.filter(reis => reis.reis_id == state.current_id );

      let soidud = null;
      if( travel.length ){
        soidud = travel[0].soidud.length ? travel[0].soidud.sort(function(a, b) {
          a = moment(a.jrk).format('YYYYMMDDHHmm');
          b = moment(b.jrk).format('YYYYMMDDHHmm');
          return a < b ? -1 : 1; // ascending order
        }) : null;
        console.log('soidud', soidud);
      }

      return soidud;
    },

    getSoidudByTravelId: state => id => {
      if( !state.travels || state.travels == null ) return null;
      let travels = state.travels.filter(reis => reis.reis_id == id ).shift();
      return travels.soidud;
    },

    getTravelById: state => id => {
      if( !state.travels || state.travels == null || !id ) return null;
      let travels =  state.travels.filter(reis => reis.reis_id == id ).shift();
      return travels ? travels : null;
    }


  },

  mutations: {

    loading( state, bool, msg ){
      state.loading = bool;
      //console.log('loading', this.state.loading);
    },

    loggingin( state, bool, msg ){
      state.loggingin = bool;
      //console.log('loading', this.state.loading);
    },

    updating( state, bool ){
      state.loading = bool;
      state.loading_msg = state.texts.updating;
      //console.log('loading', this.state.loading);
    },
    saving( state, bool ){
      state.loading = bool;
      state.loading_msg = state.texts.saving;
      //console.log('loading', this.state.loading);
    },

    setOpenSoit( state, index ){
      state.openSoit = index
    },

    setID( state, current_id ){
      state.current_id = current_id
      //console.log('current_id', this.state.current_id);
    },

    userLoggedin ( state ) {
      state.loggedinUserName = state.loggedinUser.Nimi;
    },

    newVersionRefresh( state, condition ){
      state.new_version = condition
    },

    updateNetworStatus( state, condition ){
      state.network_status = condition
    },

    setData( state, data ){
      state.travels = data
      console.log('local data load', this.state.travels);
    },

    storeDataLocally( state, data ){
      //state.travels = data;
      localStorage.setItem('travelsData', JSON.stringify(data));
      this.commit( 'setData', data);
      console.log('server data', this.state.travels);
    }

  },
  actions: {
    setData ({ commit }, data) {
      commit('setData', data)
    }
  },
  modules: {
  }
})

/**
 * Author:    Gleb Makarov
 * Created:   15.11.2021
 * Contact:   info@blueglass.ee
 * 
 * (c) Copyright by BlueGlass interactive OÜ.
 **/


import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import store from './store'
import moment from 'moment';

axios.defaults.baseURL = 'https://reisid.marsruut.ee/api/'


const myApp = createApp(App)
    .use(VueAxios, axios)
    .use(store)
    .use(router);


myApp.mixin({
    methods: {

        goHome(){
            if( this.$store.state.loggedinUser != null ){
                this.$router.push('/home')
            }else{
                this.$router.push('/')
            }
        },

        toDate(value){
            if (value) {
                return moment(String(value)).format('DD.MM.YY');
            }
        },
        toTime(value){
            if (value) {
                return moment(String(value)).format('HH:mm');
            }
        },

        formatDate: function ( date ) {
            let datesplit = date.split('-');
            let formated = datesplit[2]+'.'+datesplit[1]+'.'+datesplit[0];
            return formated;
        },

        todayDate(){
            var today = new Date();
            var day = today.getDate();
                day = day <= 9 ? '0'+day:day;
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+day;
            return this.formatDate( date );
        },

        getTeeleData() {
    
            this.error = null;
            
            if( this.$store.state.loggedinUser != null && this.$store.state.network_status == true ){
                //console.log('juht_id', this.$store.state.loggedinUser);
                this.$store.commit( 'updating', true );
        
        
                this.axios.get('get' + '?key='+this.$store.state.apikey + '&juht_id='+this.$store.state.loggedinUser).then((response) => {
                    if( response.data && response.data.status == "OK" ){
                        let data = response.data.response.data;
                        this.$store.commit( 'storeDataLocally', data );
                    }
        
                    this.$store.commit( 'updating', false );
                })
            }else{
                if( this.$store.state.network_status == false ){
                    console.log('Offline, waiting for online to update');
                }
            }
        },

        updateData() {
            //return true;
        
            this.error = null;
            
            if( this.$store.state.loggedinUser != null && this.$store.state.network_status == true ){
                //console.log('juht_id', this.$store.state.loggedinUser);
                this.$store.commit( 'updating', true );
        
                let data = {
                    'juht_id': this.$store.state.loggedinUser
                };
        
                let travels = this.$store.state.travels;
                if(travels != null){
                    data.data = travels;
                }
        
                this.axios.post('update' + '?key='+this.$store.state.apikey, data).then((response) => {
                    if( response.data && response.data.status == "OK" ){
                        let data = response.data.response.data;
                        this.$store.commit( 'storeDataLocally', data );
                    }
                    this.$store.commit( 'updating', false );
                })
            }else{
                if( this.$store.state.network_status == false ){
                    console.log('Offline, waiting for online to update');
                }
            }
        },


        saveData() {
            //return true;

            this.error = null;
            
            if( this.$store.state.loggedinUser != null && this.$store.state.network_status == true ){
                //console.log('juht_id', this.$store.state.loggedinUser);
                this.$store.commit( 'saving', true );
        
                let data = {
                    'juht_id': this.$store.state.loggedinUser
                };
        
                let travels = this.$store.state.travels;
                if(travels != null){
                    data.data = travels;
                }
        
                this.axios.post('save' + '?key='+this.$store.state.apikey, data).then((response) => {
                    if( response.data && response.data.status == "OK" ){
                        console.log('Data saved to server');
                    }
                    this.$store.commit( 'saving', false );
                })
            }else{
                if( this.$store.state.network_status == false ){
                    console.log('Offline, waiting for online to update');
                }
            }
        },

        utf8Encode(unicodeString) {
            if (typeof unicodeString != 'string') throw new TypeError('parameter ‘unicodeString’ is not a string');
            const utf8String = unicodeString.replace(
                /[\u0080-\u07ff]/g,  // U+0080 - U+07FF => 2 bytes 110yyyyy, 10zzzzzz
                function(c) {
                    var cc = c.charCodeAt(0);
                    return String.fromCharCode(0xc0 | cc>>6, 0x80 | cc&0x3f); }
            ).replace(
                /[\u0800-\uffff]/g,  // U+0800 - U+FFFF => 3 bytes 1110xxxx, 10yyyyyy, 10zzzzzz
                function(c) {
                    var cc = c.charCodeAt(0);
                    return String.fromCharCode(0xe0 | cc>>12, 0x80 | cc>>6&0x3F, 0x80 | cc&0x3f); }
            );
            return utf8String;
        },

        utf8Decode(utf8String) {
            if (typeof utf8String != 'string') throw new TypeError('parameter ‘utf8String’ is not a string');
            // note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
            const unicodeString = utf8String.replace(
                /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g,  // 3-byte chars
                function(c) {  // (note parentheses for precedence)
                    var cc = ((c.charCodeAt(0)&0x0f)<<12) | ((c.charCodeAt(1)&0x3f)<<6) | ( c.charCodeAt(2)&0x3f);
                    return String.fromCharCode(cc); }
            ).replace(
                /[\u00c0-\u00df][\u0080-\u00bf]/g,                 // 2-byte chars
                function(c) {  // (note parentheses for precedence)
                    var cc = (c.charCodeAt(0)&0x1f)<<6 | c.charCodeAt(1)&0x3f;
                    return String.fromCharCode(cc); }
            );
            return unicodeString;
        },

        showNavigation(){
            switch (this.$route.name) {
                case 'reis':
                case 'reisdetails':
                case 'completed':
                    return true;
                    break;
            
                default:
                    return false;
                    break;
            }
        },
        showDetailsBtn(){
            switch (this.$route.name) {
                case 'reis':
                    return true;
                    break;
            
                default:
                    return false;
                    break;
            }
        }

    },
});


myApp.mount('#app')

<template>
  <div>
    <HeaderElement />

    <router-view v-slot="{ Component }"  class="child-view">
      <transition
        :name="transitionName"
        mode="out-in"
        enter-active-class="animated"
        :duration="100" 
      >
        <component :is="Component" />
      </transition>
    </router-view>

    <FooterElement />
  </div>
</template>



<script>
//import TransitionPage from './transitions/TransitionPage.vue';
import FooterElement from '@/components/FooterElement.vue'
import HeaderElement from '@/components/HeaderElement.vue'

export default {
  name: 'App',

  components: {
    //TransitionPage,
    HeaderElement,
    FooterElement
  },

  data () {
    return {
      transitionName: 'slide-left'
    }
  },

  methods: {

  },

  mounted(){

      var loggedinUser = localStorage.getItem('loggedinUser');
      if( loggedinUser != null ){
        this.$store.state.loggedinUser = loggedinUser;
        //console.log('loggedinUser', loggedinUser);
      }

      var loggedinUserName = localStorage.getItem('loggedinUserName');
      if( loggedinUserName != null ){
        this.$store.state.loggedinUserName = loggedinUserName;
        //console.log('loggedinUser', loggedinUserName);
      }


      if( this.$store.state.loggedinUser == null ){
        this.$router.push('/');
      }

      var travelsData = localStorage.getItem('travelsData');
      if( travelsData != null ){
        this.$store.commit( 'setData', JSON.parse(travelsData) );
        // this.$store.dispatch('setData', {
        //   data: JSON.parse(travelsData)
        // });
      }
  },

  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
      //console.log('this.transitionName', this.transitionName);
    }
  }
    
};
</script>



<style lang="scss">
@import 'assets/scss/_mixins.scss';
@import 'assets/scss/_grid.scss';

html,
body{
  padding: 0;
  margin: 0;
}

.child-view{
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    overflow: hidden;
}

.animated{
    opacity: 0;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(100px, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-100px, 0);
}

.slide-left-enter-to,
.slide-right-enter-to{
    opacity: 0;
}
.slide-left-enter-to {
    animation-duration: 0.1s;
    animation-fill-mode: both;
    animation-name: slideLeft;
}
.slide-right-enter-to {
    animation-duration: 0.1s;
    animation-fill-mode: both;
    animation-name: slideRight;
}

@keyframes slideLeft {
  from {
    opacity: 0;
    transform: translate(100px, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes slideRight {
  from {
    opacity: 0;
    transform: translate(-100px, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $primary;
  padding-top: 31px;

  @include media-breakpoint-up(md) {
    padding-top: 50px;  
  }

  .page-reis{
    padding-top: 105px;
  }

  .page-reisdetails,
  .page-completed{
    padding-top: 55px;
  }

  *{
    box-sizing: border-box;
  }
}

.subheader{
  position: fixed;
  top: 31px;
  left: 0;
  right: 0;
  z-index: 2;

  @include media-breakpoint-up(md) {
    top: 50px;  
  }
}

.page-wrap{
  padding: 0 15px;


  h1{
      margin: 30px 0;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
  }
}


button{
  border-radius: 3px;
  background: $gray-3;
  border: none;
  padding: 4px 10px;
  min-width: 110px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: $gray;
  display: inline-block;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
    padding: 4px 10px;
    min-width: 130px;
  }

  &.submit{
    background: $secondary;
    color: #fff;
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    height: 48px;
  }
  &.back{
    background: #fff;
    border: 1px solid $gray-3;
    color: $gray-3;
    display: block;
    width: auto;
    font-size: 14px;
    line-height: 24px;
    height: 48px;
    font-weight: normal;
    margin: 0 auto;
    padding: 2px 25px 2px 23px;
    position: relative;

    &:before{
      content: url(assets/img/svg/back.svg);
      margin-right: 10px;
    }
  }

  &.green{
    background: $green;
    color: #fff;
    
    &:active,
    &:focus{
      background: darken($green, 20);
    }
  }
  &.alert{
    background: $alert;
    color: #fff;

    &:active,
    &:focus{
      background: darken($alert, 20);
    }
  }

  &.disable{
    color: $gray;
    background: $gray-3;
    pointer-events: none;
  }
}

.text-input,
.number-input{
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  color: $primary;
  padding: 2px 20px;
  border: 1px solid $gray-4;
  border-radius: 3px;
}
</style>

<template>
  <div class="login-page">
    <div class="header-image"></div>
    <div class="login-wrap page-wrap">
        <h1>Logi sisse</h1>
        
        <p class="error" v-show="error != null">{{ error }}</p>
        <p>
            <label>E-mail:</label>
            <input type="text" name="username" class="text-input" placeholder="Sisesta oma e-posti aadress" v-model="username">
        </p>
        <p>
            <label>Parool:</label>
            <input type="password" name="password" class="text-input" placeholder="Sisesta oma parool" v-model="password">
        </p>
        <p>
            <button class="submit" @click="loginUser()">Logi sisse</button>
        </p>
    </div>
  </div>
</template>

<script>

export default {
    name: 'Home',

    data() {
        return {
            error: null,
            //  username: 'viktor.komkov',
            //  password: 'OnVajaMuuta',
            username: '',
            password: ''
        }
    },

    methods: {
        loginUser() {
            let username = this.username;
            let password = this.password;

            if( username && password ){
                this.error = null;

                this.$store.commit( 'loggingin', true );

                this.axios.get('login', {
                    params: {
                        'key': this.$store.state.apikey,
                        'username': username,
                        'password': password
                    }
                }).then((response) => {
                    if( response.data.status ){
                        switch (response.data.status) {
                            case 'ERROR':
                                this.error = response.data.response;
                                break;
                        
                            case 'OK':

                                if( typeof response.data.response === 'object' ){
                                    this.$store.state.loggedinUser = response.data.response.juht_id;
                                    this.$store.state.loggedinUserName = response.data.response.Nimi;
                                    localStorage.setItem('loggedinUser', response.data.response.juht_id);
                                    localStorage.setItem('loggedinUserName', response.data.response.Nimi);
                                    this.$router.push('home');
                                }

                                break;
                        }
                    }
                    this.$store.commit( 'loggingin', false );
                })
            }
        }
    
    },

    created(){
        if( this.$store.state.loggedinUser != null ){
            this.$router.push('home');
        }
    }
}
</script>

<style lang="scss">
@import '../assets/scss/_mixins.scss';

.header-image{
    width: 100%;
    height: 112px;
    background: url('../assets/img/bus-header.jpg') no-repeat center center;
    background-size: cover;
}

.login-wrap{
    max-width: 250px;
    margin: 0 auto;

    h1{
        margin: 30px 0;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;

        @include media-breakpoint-up(md) {
            font-size: 22px;
            line-height: 28px;
        }
    }

    p{
        margin: 0 0 18px;
    }

    .error{
        color: red;
        font-weight: bold;
        font-size: 12px;
        line-height: 21px;
    }

    label{
        display: block;
        font-weight: bold;
        font-size: 12px;
        line-height: 21px;
        color: $primary;
        text-align: left;

        @include media-breakpoint-up(md) {
            font-size: 14px;
            line-height: 24px;
        }
    }

    input{
        width: 100%;
    }
}
</style>
/**
 * Author:    Gleb Makarov
 * Created:   15.11.2021
 * Contact:   info@blueglass.ee
 * 
 * (c) Copyright by BlueGlass interactive OÜ.
 **/

import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Reis from '../views/Reis.vue'
import ReisDetails from '../views/ReisDetails.vue'
import Completed from '../views/Completed.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: Home
    //component: () => import('../views/Home.vue')
  },
  {
    path: '/reis/:id',
    name: 'reis',
    component: Reis
    //component: () => import('../views/Reis.vue')
  },
  {
    path: '/reis/details/:id',
    name: 'reisdetails',
    component: ReisDetails
    //component: () => import('../views/ReisDetails.vue')
  },
  {
    path: '/reis/completed/:id',
    name: 'completed',
    component: Completed
    //component: () => import('../views/Completed.vue')
  }
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})



export default router

<template>
  <div id="nav">
    <router-link :to="returnPath()" class="go-back"></router-link> 
    <h1>{{getHeading()}}</h1>
  </div>
</template>

<script>
export default {
    name: 'Navigaton',

    methods: {
        returnPath(){
          let toreturn = '';

          switch (this.$route.name) {
            case 'reis':
              toreturn = '/home';
              break;
            case 'reisdetails':
            case 'completed':
              toreturn = '/reis/'+this.$route.params.id;
              break;
          
          }
          return toreturn;
        },
        
        getHeading(){
          let travel = this.$store.getters.getTravelById( this.$route.params.id );
          //console.log('getHeading', travel);
          if( travel != null && "klient" in travel ) return this.utf8Decode(travel.klient);
        }
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../assets/scss/_mixins.scss';

#nav {
  padding: 15px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;    
  z-index: 2;
  background: #fff;

  .go-back{
    position: absolute;
    width: 50px;
    height: 50px;
    background: none;
    left: 2px;
    top: 1px;

    &:after{
      content: '';
      background: url(../assets/img/svg/arrow-right.svg) no-repeat center center;
      background-size: 100% auto;
      width: 9px;
      height: 15px;
      position: absolute;
      top: 50%;
      left: 20px;
      margin-top: -8px;
      transform: rotate(180deg);

      @include media-breakpoint-up(md) {
        width: 12px;
        height: 20px;
        margin-top: -9px;
      }
    }
  }

  h1{
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $primary;
    margin: 0;
    justify-content: center;
    text-transform: uppercase;
  }
}
</style>
